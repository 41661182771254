/*
 *
 *   This is style for skin config
 *   Use only in demo theme
 *
*/

.theme-config {
  position: absolute;
  top: 90px;
  right: 0;
  overflow: hidden;
}

.theme-config-box {
  margin-right: -220px;
  position: relative;
  z-index: 2000;
  transition-duration: 0.8s;
}

.theme-config-box.show {
  margin-right: 0;
}

.spin-icon {
  background: $navy;
  position: absolute;
  padding: 7px 10px 7px 13px;
  border-radius: 20px 0 0 20px;
  font-size: 16px;
  top: 0;
  left: 0;
  width: 40px;
  color: #fff;
  cursor: pointer;
}

.skin-settings {
  width: 220px;
  margin-left: 40px;
  background: $gray;
}

.skin-settings .title {
  background: #efefef;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 10px 15px;
  font-size: 12px;
}

.setings-item {
  padding: 10px 30px;
}

.setings-item.skin {
  text-align: center;
}

.setings-item .switch {
  float: right;
}

.skin-name a {
  text-transform: uppercase;
}

.setings-item a {
  color: #fff;
}

.default-skin, .blue-skin, .ultra-skin, .yellow-skin {
  text-align: center;
}

.default-skin {
  font-weight: 600;
  background: #283A49;
}

.default-skin:hover {
  background: #1e2e3d;
}

.blue-skin {
  font-weight: 600;
  background: url("patterns/header-profile-skin-1.png") repeat scroll 0 0;
}

.blue-skin:hover {
  background: #0d8ddb;
}

.yellow-skin {
  font-weight: 600;
  background: url("patterns/header-profile-skin-3.png") repeat scroll 0 100%;
}

.yellow-skin:hover {
  background: #ce8735;
}

.ultra-skin {
  padding: 20px 10px;
  font-weight: 600;
  background: url("patterns/3.png") repeat scroll 0 0;
}

.ultra-skin:hover {
  background: url("patterns/4.png") repeat scroll 0 0;
}
